var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-vue-table',{ref:"table",staticClass:"pl-3 pr-3",attrs:{"title":"Staffs","fields":_vm.fields,"url":_vm.listUrl,"per-page":10,"extra-params":{role: _vm.role, status: _vm.status}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"btn-group ml-3"},[_c('drop-btn',{attrs:{"text":"Filters","icon":"fa fa-filter","size":"md","dropdown-width":"30r"}},[_c('div',{staticClass:"p-3"},[_c('validated-ajax-vue-select',{attrs:{"name":"Designation","url":_vm.roleOptions,"label":"Designation"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('validated-vue-select',{attrs:{"name":"Status","options":_vm.statusOption,"label":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('btn',{attrs:{"color":"primary","icon":"fa fa-plus","size":"md","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})],1)]},proxy:true},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',[(rowData.active_status)?_c('span',[_vm._v("Active")]):_c('span',[_vm._v("Suspended")])])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[(rowData.active_status)?_c('btn',{staticClass:"btn-basic-b",attrs:{"color":"primary","size":"xs","text":"Suspend"},on:{"click":function($event){return _vm.suspendUser(rowData)}}}):_c('btn',{staticClass:"btn-basic-b",attrs:{"color":"primary","size":"xs","text":"Retrieve"},on:{"click":function($event){return _vm.suspendUser(rowData)}}}),_c('btn',{staticClass:"btn-basic-b",attrs:{"color":"primary","size":"xs","text":"View"},on:{"click":function($event){return _vm.viewDetails(rowData)}}}),_c('btn',{staticClass:"btn-basic-b",attrs:{"color":"primary","size":"xs","text":"Attendance"},on:{"click":function($event){return _vm.viewReport(rowData)}}})],1)]}}])}),_c('modal',{ref:"addModal",staticClass:"p-0",attrs:{"no-close-on-backdrop":"","title":"Add Staff","width":"30r"}},[_c('div',{staticClass:"mb-3"}),_c('b-form',{attrs:{"save-url":_vm.addUrl},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"placeholder":"Full Name","disabled":loading,"rules":_vm.rules.name},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('validated-vue-select',{attrs:{"placeholder":"Gender","options":_vm.genderOptions,"disabled":loading,"rules":_vm.rules.gender},model:{value:(model.gender),callback:function ($$v) {_vm.$set(model, "gender", $$v)},expression:"model.gender"}}),_c('validated-ajax-vue-select',{attrs:{"placeholder":"Role","url":_vm.roleOptions,"disabled":loading,"rules":_vm.rules.role},model:{value:(model.role),callback:function ($$v) {_vm.$set(model, "role", $$v)},expression:"model.role"}}),_c('validated-input',{attrs:{"placeholder":"Email","type":"email","disabled":loading,"rules":_vm.rules.email},model:{value:(model.email),callback:function ($$v) {_vm.$set(model, "email", $$v)},expression:"model.email"}}),_c('validated-input',{attrs:{"placeholder":"Mobile Number","type":"number","disabled":loading,"rules":_vm.rules.mobile_number},model:{value:(model.mobile_number),callback:function ($$v) {_vm.$set(model, "mobile_number", $$v)},expression:"model.mobile_number"}}),_c('btn',{attrs:{"size":"sm","text":"Save","loading":loading,"loading-text":"Saving..."}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <custom-vue-table class="pl-3 pr-3" title="Staffs" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                          :extra-params="{role, status}">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" icon="fa fa-filter" size="md" dropdown-width="30r">
                        <div class="p-3">
                            <validated-ajax-vue-select name="Designation" :url="roleOptions"
                                                       label="Designation"
                                                       v-model="role"/>
                            <validated-vue-select name="Status" :options="statusOption"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn>
                    <btn color="primary" icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
                </div>
            </template>
            <template #status="{rowData}">
                <div>
                    <span v-if="rowData.active_status">Active</span>
                    <span v-else>Suspended</span>
                </div>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn v-if="rowData.active_status" class="btn-basic-b" color="primary" size="xs"
                         @click="suspendUser(rowData)" text="Suspend"/>
                    <btn v-else color="primary" class="btn-basic-b" size="xs" @click="suspendUser(rowData)"
                         text="Retrieve"/>
                    <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
                    <btn color="primary" class="btn-basic-b" size="xs" @click="viewReport(rowData)" text="Attendance"/>

                </div>
            </template>
        </custom-vue-table>
        <modal no-close-on-backdrop title="Add Staff" class="p-0" ref="addModal" width="30r">
            <div class="mb-3"></div>
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <validated-input placeholder="Full Name" v-model="model.name" :disabled="loading"
                                 :rules="rules.name"/>
                <validated-vue-select placeholder="Gender" :options="genderOptions" v-model="model.gender"
                                      :disabled="loading" :rules="rules.gender"/>
                <validated-ajax-vue-select placeholder="Role" :url="roleOptions" v-model="model.role"
                                           :disabled="loading" :rules="rules.role"/>
                <validated-input placeholder="Email" type="email" v-model="model.email" :disabled="loading"
                                 :rules="rules.email"/>
                <validated-input placeholder="Mobile Number" type="number" v-model="model.mobile_number"
                                 :disabled="loading" :rules="rules.mobile_number"/>
                <btn size="sm" text="Save" :loading="loading"
                     loading-text="Saving..."></btn>
            </b-form>
        </modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'Staffs',
    data () {
        return {
            role               : null,
            status             : null,
            designationOptions : urls.hrAdmin.staff.designationOptions,
            statusOptions      : urls.hrAdmin.staff.statusOptions,
            roleOptions        : urls.hrAdmin.staff.roleOptions,
            listUrl            : urls.hrAdmin.staff.list,
            addUrl             : urls.hrAdmin.staff.add,
            fields             : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'No'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'mobile',
                    sortField : 'mobile',
                    title     : 'Mobile'
                },
                {
                    name      : 'email',
                    sortField : 'email',
                    title     : 'E-Mail'
                },
                {
                    name      : 'role',
                    sortField : 'role',
                    title     : 'Role'
                },
                {
                    name      : '__slot:status',
                    sortField : 'active_status',
                    title     : 'Status'
                },
                {
                    name       : '__slot:actions',
                    title      : '',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ],
            genderOptions : [
                { label : 'Male', value : 'M' },
                { label : 'Female', value : 'F' },
                { label : 'Other', value : 'O' }
            ],
            statusOption : [
                { label : 'Active', value : 'True' },
                { label : 'Suspended', value : 'False' }
            ],
            rules : {
                mobile_number : {
                    required : true,
                    max      : 10,
                    min      : 10
                },
                name : {
                    required : true
                },
                gender : {
                    required : true
                },
                role : {
                    required : true
                },
                email : {
                    required : true
                }
            }
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/staff/' + rowData.id + '/details/');
        },
        viewReport (rowData) {
            this.$router.push('/attendance/' + rowData.id + '/report/');
        },
        suspendUser (item) {
            const that = this;
            axios.form(urls.hrAdmin.staff.suspendUser, { user : item.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.suspendSuccess(json);
                } else {
                    that.suspendFailure(json);
                }
            });
        },
        suspendSuccess () {
            this.$refs.table.refreshTable();
        },
        suspendFailure () {
            this.$refs.table.refreshTable();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
